const hostname = window.location?.hostname;

//Servers
const HOST_LOCAL = 'localhost';
const HOST_DEV = 'atlas-dev.outlogic.io';
const HOST_PRODUCTION = 'atlas.outlogic.io';
const HOST_QA = 'atlas-qa.outlogic.io';

export const ENV_UNKNOWN = 'unknown';
export const ENV_LOCAL = 'local';
export const ENV_DEV = 'dev';
export const ENV_PRODUCTION = 'prod';
export const ENV_QA = 'qa';

export const getEnvironmentName = () => {
  switch (hostname) {
    case HOST_LOCAL:
      return ENV_LOCAL;
    case HOST_PRODUCTION:
      return ENV_PRODUCTION;
    case HOST_DEV:
      return ENV_DEV;
    case HOST_QA:
      return ENV_QA;
    default:
      return ENV_UNKNOWN;
  }
};

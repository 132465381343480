import { reducer as formReducer } from 'redux-form';
import { appInitialState, appReducer } from './app';
import { authInitialState, authReducer } from './auth';
import { reportInitialState, reportReducer } from './report';
import { revenueInitialState, revenueReducer } from './revenue';
import { publisherInitialState, publisherReducer } from './publisher';
import { networkInitialState, networkReducer } from './network';
import { sdkInitialState, sdkReducer } from './sdk';

export default () => {
  const initialState = {
    form: {},
    app: appInitialState,
    auth: authInitialState,
    publisher: publisherInitialState,
    report: reportInitialState,
    revenue: revenueInitialState,
    network: networkInitialState,
    sdk: sdkInitialState,
  };

  const rootReducer = (state, action) => {
    // Inital state
    if (typeof state === 'undefined') {
      return initialState;
    }

    return {
      app: appReducer(state.app, action),
      auth: authReducer(state.auth, action),
      form: formReducer(state.form, action),
      publisher: publisherReducer(state.publisher, action),
      report: reportReducer(state.report, action),
      revenue: revenueReducer(state.revenue, action),
      network: networkReducer(state.network, action),
      sdk: sdkReducer(state.sdk, action),
    };
  };
  return rootReducer;
};

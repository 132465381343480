import { emptyFetchState, emptyFormState } from '../emptyState';

import { publisherInvoicesReducer } from './publisherInvoices';
import { publisherRevenueReducer } from './publisherRevenue';
import { newInvoiceReducer } from './newInvoice';

export const revenueInitialState = {
  publisherRevenue: emptyFetchState,
  newInvoice: emptyFormState,
  publisherInvoices: emptyFetchState,
};

export function revenueReducer(state, action) {
  return {
    publisherRevenue: publisherRevenueReducer(state.publisherRevenue, action),
    newInvoice: newInvoiceReducer(state.newInvoice, action),
    publisherInvoices: publisherInvoicesReducer(state.publisherInvoices, action),
  };
}

import { emptyFetchState } from '../emptyState';

import { overviewReportOptionsReducer } from './overviewReportOptions';
import { overviewReportStatsReducer } from './overviewReportStats';
import { appReportOptionsReducer } from './appReportOptions';
import { appReportStatsReducer } from './appReportStats';

export const reportInitialState = {
  overviewReportOptions: emptyFetchState,
  overviewReportStats: emptyFetchState,
  appReportOptions: emptyFetchState,
  appReportStats: emptyFetchState,
};

export const reportReducer = (state, action) => {
  return {
    overviewReportOptions: overviewReportOptionsReducer(state.overviewReportOptions, action),
    overviewReportStats: overviewReportStatsReducer(state.overviewReportStats, action),
    appReportOptions: appReportOptionsReducer(state.appReportOptions, action),
    appReportStats: appReportStatsReducer(state.appReportStats, action),
  };
};

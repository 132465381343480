import { getData } from '../_base';
import { apiDomain } from 'util/environment/api';


export const getClientsWithApps = () => {
  const url = apiDomain + '/publisher/apps';
  return getData(url);
};

export const getReportData = (reportUid) => {
  const url = `${apiDomain}/reports/data/${reportUid}`;
  return getData(url);
};

export const getReportOptions = (data) => {
  let query = '';

  if (data.clientId) {
    query += `clientId=${data.clientId}`;
  }

  if (data.appId) {
    query += `${query ? '&' : ''}appId=${data.appId}`;
  }

  if (data.reportType) {
    query += `${query ? '&' : ''}reportType=${data.reportType}`;
  }

  const url = `${apiDomain}/reports/options?${query}`;
  return getData(url);
};

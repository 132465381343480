import React from 'react';
import CircularProgress from '@mui/material/CircularProgress';
import './styles.scss';

const Spinner = () => (
  <div className="Spinner" data-testid="Spinner">
    <CircularProgress />
  </div>
);

export default Spinner;

export const emptyFetchState = {
  payload: null,
  fetching: false,
};

export const emptyFormState = {
  status: null,
  payload: null,
  fetching: false,
  error: false,
};

import { publisherContextInitialState, publisherContextReducer } from './publisherContext';
import { pathContextInitialState, pathContextReducer } from './pathContext';
import { overviewPageInitialState, overviewPageReducer } from './overviewPage';
import { appsPageInitialState, appsPageReducer } from './appsPage';
import { zoomInitialState, zoomReducer } from './zoom';
import { routeInitialState, routeReducer } from './route';

export const appInitialState = {
  publisherContext: Object.assign({}, publisherContextInitialState),
  pathContext: Object.assign({}, pathContextInitialState),
  zoom: Object.assign({}, zoomInitialState),
  appsPage: Object.assign({}, appsPageInitialState),
  overviewPage: Object.assign({}, overviewPageInitialState),
  route: Object.assign({}, routeInitialState),
};

export const appReducer = (state, action) => {
  return {
    publisherContext: publisherContextReducer(state.publisherContext, action),
    pathContext: pathContextReducer(state.pathContext, action),
    zoom: zoomReducer(state.zoom, action),
    appsPage: appsPageReducer(state.appsPage, action),
    overviewPage: overviewPageReducer(state.overviewPage, action),
    route: routeReducer(state.route, action),
  };
};

import { getData, postData } from '../_base';
import { apiDomain } from 'util/environment/api';

export const getAllPublishers = () => {
  const url = apiDomain + '/publisher/all';
  return getData(url);
};

export const postForgotPassword = (data) => {
  const url = apiDomain + '/publisher/password/forgot';
  return postData(url, data, true);
};

export const postSetPassword = (data) => {
  const url = apiDomain + '/publisher/password/reset';
  return postData(url, data, true);
};

export const postPublisherInvite = (data) => {
  const url = apiDomain + '/publisher/invite';
  return postData(url, data);
};

export const getPublisherApps = (id) => {
  const url = apiDomain + '/publisher/apps/' + id;
  return getData(url);
};

export const postUpdatePublisher = (data) => {
  const url = apiDomain + '/publisher/update';
  return postData(url, data);
};

export const getPublisherResources = (id) => {
  const url = apiDomain + '/publisher/resources/' + id;
  return getData(url);
};

export const getBitbucketAuth = (repoSlug) => {
  const url = apiDomain + '/publisher/bitbucketAuth/' + repoSlug;
  return getData(url);
};

export const postCreateDcdkApp = (data) => {
  const url = apiDomain + '/publisher/dcdk-app/create';
  return postData(url, data);
};

export const getAndroidDocs = (params: Object) => {
  const url = apiDomain + '/publisher/android/docs?clientId=' + params.publisherId + '&appId=' + params.appId;
  return getData(url);
};

export const getAndroidGeneratedInput = (params: Object) => {
  const url =
    apiDomain +
    '/publisher/android/generated-input?clientId=' +
    params.publisherId +
    '&appId=' +
    params.appId +
    '&approved=' +
    params.approved;
  return getData(url);
};

export const postEditGeneratedInput = (data) => {
  const url = apiDomain + '/publisher/android/generated-input/edit';
  return postData(url, data);
};

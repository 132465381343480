import { createSlice } from '@reduxjs/toolkit';
import { emptyFormState } from '../emptyState';
import { postStatus } from '../status';
import { getLoginRedirectLink } from '../../api/authorization';

// Then, handle actions in your reducers:
const redirectLinkSlice = createSlice({
  name: 'redirectLink',
  emptyFormState,
  reducers: {
    redirectLinkGetStart: (state) => {
      state.status = postStatus.POST_START;
      state.fetching = true;
    },
    redirectLinkGetSuccess: (state, link) => {
      state.status = postStatus.POST_SUCCESS;
      state.link = link.payload;
      state.fetching = false;
    },
    redirectLinkGetFail: (state, { link }) => {
      state.status = postStatus.POST_FAIL;
      state.link = link;
      state.fetching = false;
      state.error = true;
    },
  },
});

// actions generated from the slice, TODO: remove start,success,fail
export const { redirectLinkGetStart, redirectLinkGetSuccess, redirectLinkGetFail } = redirectLinkSlice.actions;

// The reducer
export const redirectLinkReducer = redirectLinkSlice.reducer;

// Asynchronous thunk action
export const redirectLinkGet = () => (dispatch) => {
  dispatch(redirectLinkGetStart());

  getLoginRedirectLink()
    .then((payload) => {
      dispatch(redirectLinkGetSuccess(payload.data.link));
    })
    .catch((error) => {
      dispatch(redirectLinkGetFail(error));
    });
};

import { emptyFetchState, emptyFormState } from '../emptyState';

import { allPublishersReducer } from './allPublishers';
import { bitbucketAuthReducer } from './bitbucketAuth';
import { forgotPasswordReducer } from './forgotPassword';
import { publisherInviteReducer } from './publisherInvite';
import { publisherAppsReducer } from './publisherApps';
import { publisherResourcesReducer } from './publisherResources';
import { setPasswordReducer } from './setPassword';
import { updatePublisherReducer } from './updatePublisher';
import { createDcdkAppReducer } from './createDcdkApp';
import { androidDocsReducer } from './androidDocs';
import { androidGeneratedInputReducer } from './androidGeneratedInput';
import { androidUnapprovedGeneratedInputReducer } from './androidUnapprovedGeneratedInput';
import { editGeneratedInputReducer } from './editGeneratedInput';

export const publisherInitialState = {
  allPublishers: emptyFetchState,
  forgotPassword: emptyFormState,
  setPassword: emptyFormState,
  publisherInvite: emptyFormState,
  publisherApps: emptyFetchState,
  updatePublisher: emptyFormState,
  publisherResources: emptyFetchState,
  bitbucketAuth: emptyFetchState,
  createDcdkApp: emptyFormState,
  androidDocs: emptyFetchState,
  androidGeneratedInput: emptyFetchState,
  androidUnapprovedGeneratedInput: emptyFetchState,
  editGeneratedInput: emptyFormState,
};

export const publisherReducer = (state, action) => {
  return {
    allPublishers: allPublishersReducer(state.allPublishers, action),
    bitbucketAuth: bitbucketAuthReducer(state.bitbucketAuth, action),
    forgotPassword: forgotPasswordReducer(state.forgotPassword, action),
    setPassword: setPasswordReducer(state.setPassword, action),
    publisherInvite: publisherInviteReducer(state.publisherInvite, action),
    publisherApps: publisherAppsReducer(state.publisherApps, action),
    publisherResources: publisherResourcesReducer(state.publisherResources, action),
    updatePublisher: updatePublisherReducer(state.updatePublisher, action),
    createDcdkApp: createDcdkAppReducer(state.createDcdkApp, action),
    androidDocs: androidDocsReducer(state.androidDocs, action),
    androidGeneratedInput: androidGeneratedInputReducer(state.androidGeneratedInput, action),
    androidUnapprovedGeneratedInput: androidUnapprovedGeneratedInputReducer(
      state.androidUnapprovedGeneratedInput,
      action
    ),
    editGeneratedInput: editGeneratedInputReducer(state.editGeneratedInput, action),
  };
};

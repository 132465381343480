import { emptyFormState } from '../emptyState';

import { clientsReducer } from './clients';
import { reportOptionsReducer } from './reportOptions';
import { reportDataReducer } from './reportData';

export const networkInitialState = {
  clients: emptyFormState,
  reportData: emptyFormState,
  reportOptions: emptyFormState,
};

export const networkReducer = (state, action) => {
  return {
    clients: clientsReducer(state.clients, action),
    reportData: reportDataReducer(state.reportData, action),
    reportOptions: reportOptionsReducer(state.reportOptions, action),
  };
};

import { emptyFormState } from '../emptyState';

import { settingsReducer } from './settings';
import { clientsReducer } from './clients';
import { appSettingReducer } from './appSetting';

export const sdkInitialState = {
  settings: emptyFormState,
  clients: emptyFormState,
  appSetting: emptyFormState,
};

export const sdkReducer = (state, action) => {
  return {
    settings: settingsReducer(state.settings, action),
    clients: clientsReducer(state.clients, action),
    appSetting: appSettingReducer(state.appSetting, action),
  };
};

import React from 'react';
import { Link } from 'react-router-dom';
import { useSelector } from 'react-redux';
import List from '@mui/material/List';
import Divider from '@mui/material/Divider';
import { makeStyles } from '@mui/styles';

import AppMenuItem from './AppMenuItem';
import { adminRoutes, userRoutes } from '../routes';
import * as packageInfo from '../../../../package.json';
import AtlasLogo from 'assets/images/atlas-logo-white_placeholder_powered-by-outlogic.svg';

const useStyles = makeStyles((theme) => ({
  toolbar: theme.mixins.toolbar,
}));

const NavDrawer = () => {
  const classes = useStyles();
  const [routes, setRoutes] = React.useState();
  const token = useSelector((state) => state.auth.token.payload);
  const route = useSelector((state) => state.app.route);

  React.useEffect(
    () => {
      token?.role.includes('admin') && !route.hasParam ? setRoutes(adminRoutes) : setRoutes(userRoutes);
      token?.role.includes('user') && setRoutes(userRoutes);
    }, // eslint-disable-next-line react-hooks/exhaustive-deps
    [token, route]
  );

  return (
    <>
      <div className={`Navigation__Drawer__Toolbar ${classes.toolbar}`}>
        <Link to={token?.role.includes('admin') ? '/admin/app/home' : '/app/overview'}>
          <img src={AtlasLogo} alt="Atlas Logo" width={290} height={60} />
        </Link>
      </div>
      <Divider />
      <div className="Navigation__Drawer__Content">
        <List className="Navigation__Drawer__Content__List">
          {routes?.map((route, index) => {
            return <AppMenuItem key={index} {...route} />;
          })}
        </List>
        <div className="Navigation__Drawer__Content__Footer">
          <p>
            Version: <span>{packageInfo.version}</span>
          </p>
          <p> Powered by Outlogic </p>
          &copy; 2023 Outlogic. All Rights Reserved.
        </div>
      </div>
    </>
  );
};

export default NavDrawer;

import React from 'react';
import { Routes, Route, useNavigate } from 'react-router-dom';
import { roles } from 'util/auth/roles';
import Navigation from 'components/Navigation';
import CookieTokenContainer from 'containers/CookieTokenContainer';
import Spinner from 'components/DataLoading/Spinner';
import PrivateRoute from 'components/Auth/PrivateRoute';
import InactivityTimer from 'util/inactiveTimer';
import { useDispatch } from 'react-redux';
import { tokenReset } from '../../redux/auth/token';

const Login = React.lazy(() => import('./Login'));
const Error = React.lazy(() => import('./Error'));
const UserApp = React.lazy(() => import('../UserApp'));
const AdminApp = React.lazy(() => import('../AdminApp'));
const NewPassword = React.lazy(() => import('./NewPassword'));
const ForgotPassword = React.lazy(() => import('./ForgotPassword'));

const App = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();

  React.useEffect(() => {
    const timer = new InactivityTimer({
      timeout: 1800,
      onTimeout: () => {
        dispatch(tokenReset());
        localStorage.removeItem('_expiredTime');
        navigate('/');
      },
      onExpired: () => {
        localStorage.removeItem('_expiredTime');
        dispatch(tokenReset());
        navigate('/');
      },
    });

    return () => {
      timer.cleanUp();
    };
  }, []);

  return (
    <CookieTokenContainer>
      <Navigation>
        <React.Suspense fallback={<Spinner />}>
          <Routes>
            <Route exact path="/" element={<Login />} />
            <Route exact path="/error" element={<Error />} />
            <Route path="/forgot-password" element={<ForgotPassword />} />
            <Route path="/new-password" element={<NewPassword />} />
            <Route
              path="/admin/app/*"
              element={<PrivateRoute path="/admin/app/*" component={<AdminApp />} allow={roles.ADMIN} />}
            />
            <Route path="/app/*" element={<UserApp />} />
          </Routes>
        </React.Suspense>
      </Navigation>
    </CookieTokenContainer>
  );
};

export default App;

import { createSlice } from '@reduxjs/toolkit';
import { emptyFormState } from '../emptyState';
import { fetchStatus } from '../status';
import { getSdkSettings, updateSdkSetting } from '../../api/sdk';


const settingsSlice = createSlice({
  name: 'settings',
  emptyFormState,
  reducers: {
    settingsFetchStart: (state) => {
      state.status = fetchStatus.FETCH_START;
      state.fetching = true;
    },
    settingsFetchSuccess: (state, { payload }) => {
      state.status = fetchStatus.FETCH_SUCCESS;
      state.payload = payload;
      state.fetching = false;
      state.error = false;
    },
    settingsFetchFail: (state, { payload }) => {
      state.status = fetchStatus.FETCH_SUCCESS;
      state.payload = payload;
      state.fetching = false;
      state.error = true;
    },
  },
});

export const { settingsFetchStart, settingsFetchSuccess, settingsFetchFail } = settingsSlice.actions;

export const settingsReducer = settingsSlice.reducer;

export const fetchSdkSettings = (data) => (dispatch) => {
  dispatch(settingsFetchStart());
  getSdkSettings(data)
    .then((payload) => {
      dispatch(settingsFetchSuccess(payload.data));
    })
    .catch((error) => {
      dispatch(settingsFetchFail(error));
    });
};

export const updateSdkSettings = (data) => (dispatch) => {
  updateSdkSetting(data)
    .then((payload) => {
      dispatch(settingsFetchSuccess(payload.data));
    })
    .catch((error) => {
      dispatch(settingsFetchFail(error));

    });
};

// Role name constants.
const ADMIN = 'admin';
const USER = 'user';

// All roles.
export const allRoles = [ADMIN, USER];

const LEVEL_ADMIN = allRoles.slice(0, 1);
const LEVEL_USER = allRoles;

// Exported roles object.
export const roles = {
  all: allRoles,

  ADMIN,
  USER,

  LEVEL_ADMIN,
  LEVEL_USER,
};

export const authorizeRole = (activeRole, allowedRoles) => {
  return allowedRoles.includes(activeRole);
};

export const authorizeAppRole = (path, actions) => {
  if (!path) {
    return true;
  }
  return actions[path] && actions[path].length > 0 && actions[path].includes('read');
};

import { getData, postData } from '../_base';
import { apiDomain } from 'util/environment/api';

export const getPublisherRevenue = (id: string) => {
  const url = apiDomain + '/revenue/stats?clientId=' + id;
  return getData(url);
};

export const postNewInvoice = (data) => {
  const url = apiDomain + '/revenue/invoice/new';
  return postData(url, data);
};

export const getPublisherInvoices = (id: string) => {
  const url = apiDomain + '/revenue/invoices?publisherId=' + id;
  return getData(url);
};

export const getPublisherPaymentRules = (id: string) => {
  const url = apiDomain + '/revenue/paymentRules?clientId=' + id;
  return getData(url);
};

export const postNewPaymentRule = (data) => {
  const url = apiDomain + '/revenue/paymentRules/new';
  return postData(url, data);
};

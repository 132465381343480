import { createSlice } from '@reduxjs/toolkit';
import { emptyFormState } from '../emptyState';
import { fetchStatus } from '../status';
import { getSdkAppSetting } from '../../api/sdk';


const appSettingSlice = createSlice({
  name: 'appSetting',
  emptyFormState,
  reducers: {
    appSettingFetchStart: (state) => {
      state.status = fetchStatus.FETCH_START;
      state.fetching = true;
    },
    appSettingFetchSuccess: (state, { payload }) => {
      state.status = fetchStatus.FETCH_SUCCESS;
      state.payload = payload;
      state.fetching = false;
      state.error = false;
    },
    appSettingFetchFail: (state, { payload }) => {
      state.status = fetchStatus.FETCH_SUCCESS;
      state.payload = payload;
      state.fetching = false;
      state.error = true;
    },
  },
});

export const { appSettingFetchStart, appSettingFetchSuccess, appSettingFetchFail } = appSettingSlice.actions;

export const appSettingReducer = appSettingSlice.reducer;

export const fetchSdkAppSetting = (data) => (dispatch) => {
  dispatch(appSettingFetchStart());
  getSdkAppSetting(data)
    .then((payload) => {
      dispatch(appSettingFetchSuccess(payload.data));
    })
    .catch((error) => {
      dispatch(appSettingFetchFail(error));
    });
};

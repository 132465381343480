import { emptyFormState } from '../emptyState';

import { logServiceReducer } from './logService';
import { tokenReducer } from './token';
import { redirectLinkReducer } from './redirectLink';

export const authInitialState = {
  logService: emptyFormState,
  token: emptyFormState,
  redirectLink: emptyFormState,
};

export const authReducer = (state, action) => {
  return {
    logService: logServiceReducer(state.logService, action),
    token: tokenReducer(state.token, action),
    redirectLink: redirectLinkReducer(state.redirectLink, action),
  };
};

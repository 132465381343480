import { ENV_PRODUCTION, ENV_QA, getEnvironmentName } from 'util/environment/name';
import { log } from 'util/log';

const environmentName = getEnvironmentName();

// ENV_LOCAL, ENV_DEV
let apiDomain = 'https://api.atlas-dev.outlogic.io';
log('%c> apiDomain: ', 'color: magenta', apiDomain);

if (environmentName === ENV_PRODUCTION) {
  apiDomain = 'https://api.atlas.outlogic.io';
}

if (environmentName === ENV_QA) {
  apiDomain = 'https://api.atlas-qa.outlogic.io';
}

export { apiDomain };

import React from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { tokenFromCache } from 'redux/auth/token';

import { log } from 'util/log';
import Cookies from 'js-cookie';

type CookieTokenContainerProps = {
  children: any,
};

const CookieTokenContainer = (props: CookieTokenContainerProps) => {
  const dispatch = useDispatch();
  const token = useSelector((state) => state.auth.token.payload);
  React.useEffect(
    () => {
      const cookieToken = Cookies.get('token');

      if (cookieToken) {
        const parsedCookieToken = JSON.parse(cookieToken);
        dispatch(tokenFromCache(parsedCookieToken));
      }
    }, // eslint-disable-next-line react-hooks/exhaustive-deps
    []
  );

  React.useEffect(() => {
    log('%c>>> TOKEN', 'color: magenta', token);
  }, [token]);

  return props.children;
};

export default CookieTokenContainer;

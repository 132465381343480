import { getData } from '../_base';
import { apiDomain } from 'util/environment/api';

export const getOverviewReportOptions = (id: string) => {
  const url = apiDomain + '/reports/overview/options?clientId=' + id;
  return getData(url);
};

export const getOverviewReportStats = (params: Object) => {
  const url = apiDomain + '/reports/overview/' + params.id + '?clientId=' + params.clientId;
  return getData(url);
};

export const getAppReportOptions = (params: Object) => {
  const url = apiDomain + '/reports/app/options?clientId=' + params.publisherId + '&appId=' + params.appId;
  return getData(url);
};

export const getAppReportStats = (params: Object) => {
  const url = apiDomain + '/reports/app/' + params.id + '?clientId=' + params.clientId;
  return getData(url);
};

export const getPdfExport = (params: Object) => {
  const url = apiDomain + '/reports/export?email=' + params.email + '&password=' + params.password;
  return getData(url);
};

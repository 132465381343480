import React from 'react';
import { useSelector } from 'react-redux';

import List from '@mui/material/List';
import ListItemIcon from '@mui/material/ListItemIcon';
import ListItemText from '@mui/material/ListItemText';
import Divider from '@mui/material/Divider';
import Collapse from '@mui/material/Collapse';

import IconExpandLess from '@mui/icons-material/ExpandLess';
import IconExpandMore from '@mui/icons-material/ExpandMore';
import AppMenuItemComponent from '../AppMenuItemComponent';

// RESOURCE: https://medium.com/@modularcoder/reactjs-multi-level-sidebar-navigation-menu-with-typescrip-materialui-251943c12dda
const AppMenuItem = (props) => {
  const { name, path, Icon, items = [] } = props;
  const [open, setOpen] = React.useState(true);
  const token = useSelector((state) => state.auth.token);
  const isExpandable = items?.length > 0;

  const handleClick = () => {
    setOpen(!open);
  };

  const MenuItemRoot = (
    <AppMenuItemComponent link={path} onClick={handleClick}>
      {Icon && (
        <ListItemIcon className="Navigation__Drawer__Icon">
          <Icon />
        </ListItemIcon>
      )}

      <ListItemText primary={name} />
      {isExpandable && (!open ? <IconExpandMore /> : <IconExpandLess />)}
    </AppMenuItemComponent>
  );

  const MenuItemChildren = isExpandable ? (
    <Collapse in={open} timeout="auto">
      <Divider />
      <List component="div" disablePadding>
        {items.map((item, index) => {
          if (item.name.includes('General')) return <AppMenuItem {...item} key={index} />;
          if (token?.payload?.role.includes('admin') || (token?.payload?.isOnNewSdk && item?.isOnNewSdk)) {
            return <AppMenuItem {...item} key={index} />;
          }
          return null;
        })}
      </List>
    </Collapse>
  ) : null;

  return (
    <>
      {MenuItemRoot}
      {MenuItemChildren}
    </>
  );
};

export default AppMenuItem;

import React from 'react';
import connect from 'redux/connect';

import Drawer from '@mui/material/Drawer';
import Hidden from '@mui/material/Hidden';
import { makeStyles } from '@mui/styles';
import useMediaQuery from '@mui/material/useMediaQuery';

import OutlogicWordmark from 'assets/images/outlogic-logo_white.png';
import NavAppBar from './NavAppBar';
import NavDrawer from './NavDrawer';
import './styles.scss';
import { useLocation } from 'react-router-dom';

const drawerWidth = 290;
const useStyles = makeStyles((theme) => ({
  root: {
    display: 'flex',
  },
  drawer: {
    [theme.breakpoints.up('md')]: {
      width: `${drawerWidth}px !important`,
      flexShrink: 0,
    },
  },
  drawerPaper: {
    width: drawerWidth,
  },
  content: {
    flexGrow: 1,
  },
}));

const Navigation = (props) => {
  const classes = useStyles();
  const location = useLocation();
  const [mobileOpen, setMobileOpen] = React.useState(false);
  const [desktopOpen, setDesktopOpen] = React.useState(true);
  const desktopView = useMediaQuery('(min-width:960px)', { noSsr: true });

  const handleDrawerToggle = (_desktopView) => {
    if (_desktopView) {
      setDesktopOpen(!desktopOpen);
    } else {
      setMobileOpen(!mobileOpen);
    }
  };

  const handleMobileDrawerToggle = () => {
    setMobileOpen(!mobileOpen);
  };

  if (location.pathname.indexOf('app') !== -1)
    return (
      <div className={`Navigation ${classes.root}`}>
        <NavAppBar handleDrawerToggle={handleDrawerToggle} desktopView={desktopView} desktopOpen={desktopOpen} />
        <nav className={classes.drawer} aria-label="mailbox folders">
          {desktopView ? (
            <Hidden smDown implementation="css">
              <Drawer
                className="Navigation__Drawer Desktop"
                classes={{
                  paper: classes.drawerPaper,
                }}
                variant="persistent"
                open={desktopOpen}
              >
                <NavDrawer />
              </Drawer>
            </Hidden>
          ) : (
            <Hidden mdUp implementation="css">
              <Drawer
                className="Navigation__Drawer Mobile"
                variant="temporary"
                open={mobileOpen}
                onClose={handleMobileDrawerToggle}
                classes={{
                  paper: classes.drawerPaper,
                }}
                ModalProps={{
                  keepMounted: true, // Better open performance on mobile.
                }}
              >
                <NavDrawer />
              </Drawer>
            </Hidden>
          )}
        </nav>
        <main className={`${classes.content} ${desktopOpen ? '' : 'main--fullWidth'}`}>
          {props.children}
          <footer className="Footer">
            <div className="Footer__Content">
              <img src={OutlogicWordmark} width="115" height="40" alt="Outlogic Wordmark" />
              <div className="Footer__Content__Copyright">Copyright &#169;2023 Outlogic Inc.</div>
            </div>
            <div className="Footer__Privacy">
              <a href="https://outlogic.io/privacy-policy/" target="_blank" rel="noopener noreferrer">
                Privacy Policy
              </a>
              <a href="https://outlogic.io/privacy-policy/" target="_blank" rel="noopener noreferrer">
                Terms and Conditions
              </a>
            </div>
          </footer>
        </main>
      </div>
    );

  return props.children;
};

export default connect(Navigation);

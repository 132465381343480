import { roles } from 'util/auth/roles';
import AssessmentIcon from '@mui/icons-material/Assessment';
import PaymentIcon from '@mui/icons-material/Payment';
import HelpIcon from '@mui/icons-material/Help';
import AppsIcon from '@mui/icons-material/Apps';
import PeopleAltIcon from '@mui/icons-material/PeopleAlt';
import SignalCellularAltIcon from '@mui/icons-material/SignalCellularAlt';
import HomeIcon from '@mui/icons-material/Home';
import CalendarMonthIcon from '@mui/icons-material/CalendarMonth';
import InventoryIcon from '@mui/icons-material/Inventory';

export const adminRoutes = [
  {
    name: 'Home',
    path: 'home',
    Icon: HomeIcon,
    allow: roles.LEVEL_ADMIN,
  },
  {
    name: 'Clients',
    path: 'clients',
    Icon: PeopleAltIcon,
    allow: roles.LEVEL_ADMIN,
  },
  {
    name: 'Revenue',
    path: 'revenue',
    Icon: PaymentIcon,
    allow: roles.LEVEL_ADMIN,
  },
  {
    name: 'Payment Terms',
    path: 'payment-terms',
    Icon: CalendarMonthIcon,
    allow: roles.LEVEL_ADMIN,
  },
  {
    name: 'Network Reports',
    path: 'network',
    Icon: SignalCellularAltIcon,
    allow: roles.LEVEL_ADMIN,
  },
  {
    name: 'SDK/DCDK',
    Icon: InventoryIcon,
    allow: roles.LEVEL_ADMIN,
    items: [
      { name: 'Generated Input', path: 'sdk/generated-input' },
      { name: 'Settings', path: 'sdk/settings' },
      { name: 'Versions', path: 'sdk/versions' },
    ],
  },
];

export const userRoutes = [
  {
    name: 'Overview',
    path: 'overview',
    Icon: AssessmentIcon,
    allow: roles.LEVEL_USER,
  },
  {
    name: 'Apps',
    path: 'apps',
    Icon: AppsIcon,
    allow: roles.LEVEL_USER,
  },
  {
    name: 'Revenue',
    path: 'revenue',
    Icon: PaymentIcon,
    allow: roles.LEVEL_USER,
  },
  {
    name: 'Resources',
    Icon: PeopleAltIcon,
    allow: roles.LEVEL_USER,
    items: [
      { name: 'General', path: 'resources/general' },
      { name: 'Android', path: 'resources/android', isOnNewSdk: true },
      { name: 'iOS', path: 'resources/ios', isOnNewSdk: true },
    ],
  },
  {
    name: 'Feedback',
    path: 'feedback',
    Icon: HelpIcon,
    allow: roles.LEVEL_USER,
  },
];

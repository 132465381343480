import React from 'react';
import { useSelector } from 'react-redux';
import { Navigate } from 'react-router';
import { authorizeAppRole, authorizeRole } from 'util/auth/roles';

/* PrivateRoute: Wrap a component in a PrivateComponent, and pass in the allowed roles
 * as the `allow` parameter.
 * PrivateComponent will allow/deny entry based on if the role is authorized to view it.
 *
 * Use PrivateRoute for routes of the app where you want to deny entry for unauthorized roles.
 * PrivateRoute will kick the user out of that page, and return a 401 error.
 * For smaller sections of the app, where you want to hide/show a component but not kick the user out,
 * instead use `PrivateComponent`.
 *
 * Example usage:
 *
 *  import { roles } from 'util/auth/roles';
 *
 *  <PrivateRoute
 *    allow={roles.LEVEL_CLADMIN}
 *    component={MyComponent}
 *  />
 */

const RedirectOrNull = (props: { redirect: boolean }) => {
  if (props.redirect) {
    return (
      <Navigate
        to={{
          pathname: '/error',
        }}
        replace
      />
    );
  } else {
    return null;
  }
};

type PrivateRouteProps = {
  component: React.Element<any>,
  allow: [],
  token: string,
  elemPath?: string,
  redirect: boolean, // Redirect to login if unauthorized
};

const PrivateRoute = ({ component, allow, redirect, elemPath }: PrivateRouteProps) => {
  const token = useSelector((state) => state.auth.token);
  let authorized = false;

  if (token.payload) {
    authorized = authorizeRole(token.payload.role, allow) && authorizeAppRole(elemPath, token.payload.authorizedApps);
    redirect = true; // once authorized is updated, allow for app to redirect to 401 page if needed
  }

  return authorized ? component : <RedirectOrNull redirect={redirect} />;
};

export default PrivateRoute;

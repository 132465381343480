import { postData, getDataWithoutAuth } from '../_base'; //reusable functions to handle GET and POST requests
import { apiDomain } from 'util/environment/api'; //determines the api domain based on environment

//postToken() will be used with redux to make our api calls
//see redux/actionCreators/token.js to see how postToken() is currently being used
export const postToken = (data) => {
  const url = apiDomain + '/auth/getUserToken';

  return postData(url, data, true); //postData() returns an axios request in this case a POST
};

export const postLogService = (data) => {
  const url = apiDomain + '/auth/logService';

  return postData(url, data);
};

export const getLoginRedirectLink = () => {
  const url = apiDomain + '/auth/login';

  return getDataWithoutAuth(url);
};

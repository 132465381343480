import React from 'react';
import { useSelector } from 'react-redux';
import { NavLink, NavLinkProps } from 'react-router-dom';
import ListItem from '@mui/material/ListItem';

const AppMenuItemComponent = (props) => {
  const { onClick, link, children } = props;
  const pathContext = useSelector((state) => state.app.pathContext);
  const fullUrl = `${pathContext.baseUrl}/${link}`;

  //Necessary for "Resources" link to collapse properly
  if (!link || typeof link !== 'string') {
    return <ListItem button children={children} onClick={onClick} />;
  }

  return (
    <ListItem
      button
      children={children}
      /* eslint-disable-next-line react/display-name */
      component={React.forwardRef((_props: NavLinkProps, ref: any) => (
        <NavLink
          {..._props}
          innerref={ref}
          className={({ isActive }) => _props.className + (isActive ? ' selected' : '')}
        />
      ))}
      to={fullUrl}
      onClick={onClick}
    />
  );
};

export default AppMenuItemComponent;

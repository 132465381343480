import { getData, postData } from '../_base';
import { apiDomain } from 'util/environment/api';


export const getSdkSettings = (data) => {
  const url = `${apiDomain}/sdk/settings?clientId=${data.clientId}&appId=${data.appId}&platform=${data.platform}`;
  return getData(url);
};

export const getSdkAppSetting = (data) => {
  const url = `${apiDomain}/sdk/settings/app`;
  return postData(url, data);
};

export const updateSdkSetting = (data) => {
  const url = `${apiDomain}/sdk/settings`;
  return postData(url, data);
};

export const getClients = () => {
  const url = apiDomain + '/publisher/sdk';
  return getData(url);
};

import axios from 'axios';
import { log } from 'util/log';
import { store } from 'views/App/Redux';

export const getData = async (url) => {
  log('%cGET%c', 'color: blue', 'color: black', url);

  const token = store.getState().auth.token.payload.token;
  const headers = {
    'Content-Type': 'application/json',
    Authorization: token,
  };
  return await axios({
    method: 'get',
    url: url,
    headers: headers,
  });
};


export const getGzipData = async (url) => {
  log('%cGET%c', 'color: blue', 'color: black', url);

  const token = store.getState().auth.token.payload.token;
  const headers = {
    'Content-Type': 'application/json',
    Authorization: token,
  };
  return await axios({
    method: 'get',
    url: url,
    responseType: 'arraybuffer',
    decompress: true,
    headers: headers,
  });
};


export const getDataWithoutAuth = async (url) => {
  log('%cGET%c', 'color: blue', 'color: black', url);

  const headers = {
    'Content-Type': 'application/json',
  };
  return await axios({
    method: 'get',
    url: url,
    headers: headers,
  });
};

export const postData = async (url, data, noToken) => {
  log('%cPOST%c', 'color: blue', 'color: black', url, data);

  let headers = {
    'Content-Type': 'application/json',
  };
  if (!noToken) {
    const token = store.getState().auth.token.payload.token;
    headers = {
      'Content-Type': 'application/json',
      Authorization: token,
    };
  }
  return await axios({
    method: 'post',
    url: url,
    data: data,
    headers: headers,
  });
};

import React from 'react';
import connect from 'redux/connect';
import { useDispatch } from 'react-redux';
import { tokenReset } from 'redux/auth/token';

import { makeStyles } from '@mui/styles';
import AppBar from '@mui/material/AppBar';
import IconButton from '@mui/material/IconButton';
import MenuIcon from '@mui/icons-material/Menu';
import Toolbar from '@mui/material/Toolbar';
import Button from '@mui/material/Button';
import { useNavigate } from 'react-router-dom';

const drawerWidth = 290;
const useStyles = makeStyles((theme) => ({
  appBar: {
    marginLeft: drawerWidth,
    [theme.breakpoints.up('md')]: {
      width: `calc(100% - ${drawerWidth}px)`,
    },
  },
}));

const NavAppBar = (props) => {
  const dispatch = useDispatch();
  const classes = useStyles();
  const navigate = useNavigate();

  const handleLogoutClick = () => {
    dispatch(tokenReset());
    navigate('/');
  };

  const handleAppBarClick = () => {
    props.handleDrawerToggle(props.desktopView);
  };

  return (
    <AppBar
      position="fixed"
      className={`Navigation__Appbar ${classes.appBar} ${props.desktopOpen ? '' : 'Navigation__Appbar--fullWidth'}`}
    >
      <Toolbar className="Navigation__Appbar__Toolbar">
        <IconButton
          className="Navigation__Appbar__Hamburger"
          color="inherit"
          aria-label="open drawer"
          edge="start"
          onClick={handleAppBarClick}
        >
          <MenuIcon />
        </IconButton>
        <Button className="Navigation__Appbar__Logout" onClick={handleLogoutClick}>
          Log Out
        </Button>
      </Toolbar>
    </AppBar>
  );
};

export default connect(NavAppBar);
